import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { IfThisThenThanColumnComponent } from "./IfThisThenThatColumnComponent";
import IfThisThenThatColumnComponentType from "../../models/IfThisThenThatColumnComponentType";
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import JetpackComposeMetadata from "../../models/JetpackComposeMetadata";
import Layout from "../core/Layout";
import NewsletterRow from "../newsletter/NewsletterRow";
import PageTitle from "../core/PageTitle";
import FeaturedJobsSection from "../jobs/FeaturedJobsSection";
import MakerOSSection from "../ads/MakerOSSection";

interface IfThisThenThatComponentProps extends RouteComponentProps {
  pageContext?: {
    classicAndroid?: any;
    iftttArray: [any];
    featuredJobs: any[];
  };
}

export default function IfThisThenThatComponent(
  props: IfThisThenThatComponentProps
) {
  const classes = useStyles();
  const [selectedClassicAndroid, setClassicAndroidValue] = useState("");
  function handleChange(newValue: string) {
    setClassicAndroidValue(newValue);
  }

  const lhs = props.pageContext?.classicAndroid
    ? props.pageContext.classicAndroid
    : "X";
  const title = `What's the equivalent of ${lhs} in Jetpack Compose?`;
  const description = props.pageContext?.classicAndroid
    ? `Learn the Jetpack Compose alternative to ${lhs}. Discover recommendations for equivalent APIs in Compose. Click for more!`
    : `Learn the Jetpack Compose alternative to existing Android APIs. Discover recommendations for equivalent APIs in Compose. Click for more!`;

  const pageSlug = `/What-is-the-equivalent-of-${lhs
    .split(" ")
    .join("-")}-in-Jetpack-Compose`;

  let classicAndroidVsJetpackComposeMap = new Map<
    string,
    JetpackComposeMetadata
  >();

  if (props.pageContext && props.pageContext.iftttArray) {
    props.pageContext.iftttArray.forEach((element) => {
      classicAndroidVsJetpackComposeMap.set(
        element.classicAndroid,
        new JetpackComposeMetadata(
          element.compose,
          element.exampleUrl,
          element.documentationUrl
        )
      );
    });
  }

  useEffect(() => {
    if (
      (selectedClassicAndroid === undefined || selectedClassicAndroid === "") &&
      props.pageContext?.classicAndroid !== undefined &&
      props.pageContext?.classicAndroid !== "" &&
      classicAndroidVsJetpackComposeMap.get(
        props.pageContext.classicAndroid
      ) !== null
    ) {
      setClassicAndroidValue(props.pageContext.classicAndroid);
    }
  }, [
    selectedClassicAndroid,
    classicAndroidVsJetpackComposeMap,
    props.pageContext,
  ]);

  return (
    <>
      <Layout
        maxWidth="xl"
        pageTitle={title}
        pageDescription="Learn more about using Jetpack Compose in Android. How does Jetpack Compose compare to the existing Android UI Toolkit?"
        pageSlug={pageSlug}
        pageImageUrl="/JetpackComposeIfThen_Preview_Image.png"
        seoImageUrl="/JetpackComposeIfThen_Preview_Image.png"
      >
        <PageTitle
          header="Which Compose API to use?"
          subheader="Pick a classic Android API and get recommendations for the equivalent Jetpack Compose API to use!"
        />
        <Grid container>
          <Grid item xs={12} lg={6} className={classes.firstColumn}>
            <IfThisThenThanColumnComponent
              prefix="If you used..."
              suffix="In Classic Android 🤖💚"
              selectedKey={selectedClassicAndroid}
              map={classicAndroidVsJetpackComposeMap}
              handleChange={handleChange}
              componentType={IfThisThenThatColumnComponentType.KEY}
            />
          </Grid>
          <Grid container item xs={12} lg={6} className={classes.secondColumn}>
            <IfThisThenThanColumnComponent
              prefix="Then, you could use..."
              suffix="In Jetpack Compose 🚀"
              selectedKey={selectedClassicAndroid}
              map={classicAndroidVsJetpackComposeMap}
              handleChange={handleChange}
              componentType={IfThisThenThatColumnComponentType.VALUE}
            />
          </Grid>
        </Grid>
        <div className={classes.newsletter}>
          <NewsletterRow />
        </div>
        {/* <div className={classes.jobs}>
          <MakerOSSection />
        </div> */}
      </Layout>
    </>
  );
}

const useStyles = makeStyles({
  firstColumn: {
    background: "linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)",
    height: "80vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "10%",
      right: 0,
      width: "1px",
      height: "80%",
      background:
        "linear-gradient(180deg, transparent, rgba(0,0,0,0.1), transparent)",
    },
  },
  secondColumn: {
    background: "linear-gradient(145deg, #e8f5e9 0%, #ccff90 100%)",
    height: "80vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
  },
  newsletter: {
    marginTop: 64,
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    textAlign: "center",
    padding: 32,
    background: "#ffffff",
    borderRadius: 16,
  },
  jobs: {
    marginTop: 32,
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    padding: 32,
    background: "#ffffff",
    borderRadius: 16,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.05)",
  },
});
