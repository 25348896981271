import Autocomplete from "@material-ui/lab/Autocomplete";
import { Box } from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import IfThisThenThatColumnComponentType from "../../models/IfThisThenThatColumnComponentType";
import JetpackComposeMetadata from "../../models/JetpackComposeMetadata";
import Link from "@material-ui/core/Link";
import { navigate } from "gatsby";
import React, { FunctionComponent, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Theme } from "@material-ui/core";
import { ThemeProvider, ClassNameMap } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface IfThisThenThanColumnComponentProps {
  prefix: string;
  suffix: string;
  selectedKey: string;
  map: Map<string, JetpackComposeMetadata>;
  componentType: IfThisThenThatColumnComponentType;
  handleChange: (value: string) => void;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#ccff90",
    },
  },
});

export const IfThisThenThanColumnComponent: FunctionComponent<
  IfThisThenThanColumnComponentProps
> = (props) => {
  const classes = useStyles();
  const classicAndroid = Array.from(props.map.keys());
  const breakpointUpLg: boolean = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Box display="flex" flexDirection="column" className={classes.boxCenter}>
        <Box>
          <Typography
            variant="h4"
            align="center"
            className={getTypographyClass(props.componentType, classes)}
          >
            {props.prefix}
          </Typography>
        </Box>
        {props.componentType === IfThisThenThatColumnComponentType.KEY ? (
          <Box className={classes.autocompleteBox}>
            <ThemeProvider theme={theme}>
              <Autocomplete
                id="combo-box-demo"
                inputValue={props.selectedKey}
                options={classicAndroid}
                classes={{
                  option: classes.dropdownOptions,
                }}
                onChange={(event, newValue) => {
                  if (newValue) {
                    props.handleChange(newValue);
                    navigate(
                      "/What-is-the-equivalent-of-" +
                        newValue.split(" ").join("-") +
                        "-in-Jetpack-Compose"
                    );
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  props.handleChange(newInputValue);
                  if (newInputValue === "") {
                    navigate("/What-is-the-equivalent-of-X-in-Jetpack-Compose");
                  }
                }}
                getOptionLabel={(option) => option}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="<Start Typing>"
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        input: classes.inputTextField,
                      },
                    }}
                  />
                )}
              />
            </ThemeProvider>
          </Box>
        ) : (
          <Box>
            <Typography
              variant="h4"
              align="center"
              className={classes.composableValue}
            >
              {!validSelectedKey(props.selectedKey, props.map) ? (
                breakpointUpLg ? (
                  <div>
                    <span role="img" aria-label="Pointing in Left Direction">
                      👈
                    </span>
                  </div>
                ) : (
                  <div>
                    <span role="img" aria-label="Pointing in Up Direction">
                      ☝️
                    </span>
                  </div>
                )
              ) : (
                props.map.get(props.selectedKey)?.composableName
              )}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography
            variant="h4"
            align="center"
            className={getTypographyClass(props.componentType, classes)}
          >
            {props.suffix}
          </Typography>
        </Box>

        {props.componentType === IfThisThenThatColumnComponentType.VALUE &&
        validSelectedKey(props.selectedKey, props.map) ? (
          <>
            {props.map.get(props.selectedKey)?.composableGithubLink !== "" && (
              <Box className={classes.linkSection}>
                <Link
                  variant="overline"
                  className={classes.link}
                  href={props.map.get(props.selectedKey)?.composableGithubLink}
                  target="_blank"
                >
                  🤩 Example to help you get started 🤩
                </Link>
              </Box>
            )}

            <Box
              className={
                props.map.get(props.selectedKey)?.composableGithubLink === ""
                  ? classes.linkSection
                  : undefined
              }
            >
              <Link
                variant="overline"
                className={classes.link}
                href={props.map.get(props.selectedKey)?.officialDocsLink}
                target="_blank"
              >
                Official Docs
              </Link>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

function getTypographyClass(
  componentType: IfThisThenThatColumnComponentType,
  classes: ClassNameMap
) {
  return componentType === IfThisThenThatColumnComponentType.KEY
    ? classes.typographyKey
    : classes.typographyValue;
}

function validSelectedKey(
  key: string,
  map: Map<string, JetpackComposeMetadata>
) {
  return key && key.length !== 0 && map.get(key) !== undefined;
}

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  boxCenter: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "2rem",
  },
  typographyKey: {
    fontSize: 42,
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    color: "#424242",
    marginBottom: 80,
    marginTop: 80,
    fontWeight: 700,
    fontFamily: "'Inter', 'Nunito Sans', sans-serif",
    letterSpacing: "-0.5px",
    lineHeight: 1.2,
  },
  typographyValue: {
    fontSize: 42,
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    color: "#2e7d32",
    fontWeight: 700,
    fontFamily: "'Inter', 'Nunito Sans', sans-serif",
    letterSpacing: "-0.5px",
    lineHeight: 1.2,
  },
  linkSection: {
    marginTop: "20%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  link: {
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    fontFamily: "'Inter', sans-serif",
    color: "#1b5e20",
    textDecoration: "none",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
    transition: "all 0.2s ease-in-out",
    background: "rgba(0, 0, 0, 0.05)",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.1)",
      transform: "translateY(-1px)",
    },
  },
  autocompleteBox: {
    width: "90%",
    maxWidth: "600px",
  },
  inputTextField: {
    fontSize: 60,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
    textAlign: "center",
    fontWeight: 700,
    fontFamily: "'Inter', 'Playfair Display', serif",
    color: "#212121",
    transition: "all 0.3s ease",
  },
  composableValue: {
    fontSize: 60,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
    textAlign: "center",
    fontWeight: 700,
    fontFamily: "'Inter', 'Playfair Display', serif",
    marginBottom: 80,
    marginTop: 80,
    color: "#1b5e20",
    transition: "all 0.3s ease",
  },
  dropdownOptions: {
    fontSize: 42,
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
    },
    fontFamily: "'Inter', 'Nunito Sans', sans-serif",
    padding: "0.75rem 1rem",
    transition: "background-color 0.2s ease",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05) !important",
    },
  },
}));
